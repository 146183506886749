import React, { useEffect, useState } from 'react'
import { Flex, Heading, Text, Textarea, Input, Button, Radio, Label, Select, Box } from '@theme-ui/components'

// import backgroundStylesGradient from '../../../../../gatsby-theme-minimal/src/helpers/backgroundStylesGradient'

export default function ContactForm({
  businessData,
  configData,
  options,
  variantName,
  poweredImages,
  locationIndex,
  isMultiLocationSite
}) {
  const { name, street, city, state, zip, phone } = businessData
  const { containerStyles, title, subtitle, text, contactDetails, email, backgroundImages, backgroundGradient } =
    options

  const formName = `${name}-${city}-careers`

  const styles = containerStyles ? JSON.parse(containerStyles) : {}
  const variant = variantName ? variantName : 'contactFormV1'

  // const backgroundStyles = backgroundImages
  //   ? backgroundStylesGradient(
  //       backgroundImages[locationIndex],
  //       backgroundGradient,
  //       poweredImages,
  //       locationIndex
  //     )
  //   : ''

  return (
    <Flex
      variant={`${variant}.container`}
      sx={{
        // backgroundImage: backgroundStyles,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        ...styles
      }}
    >
      <Heading as="h2" variant={`${variant}.title`} className="title" sx={customStyles.title}>
        Careers with The Coastal Gourmet Group
      </Heading>

      {/* {subtitle && (
        <Heading as='h3' variant={`${variant}.subtitle`} className='subtitle'>
        {subtitle}
        </Heading>
      )} */}
      {/* {text && (
        <Text as='p' variant={`${variant}.text`} className='text'>
          For more information about our private space and to check availability please email us at:
          info@limani.com
        </Text>
      )} */}

      <Text
        as="p"
        sx={{
          maxWidth: '767px',
          padding: '1rem',
          margin: '0rem auto 2rem',
          opacity: '0.8'
        }}
      >
        We are always hiring for our growing company. If you thrive in a fast paced environment, are reliable and want
        to work with a team who has built a reputation for providing our community with culinary offerings made of the
        highest quality, freshest, sustainable ingredients, we want to hear from you! We encourage you to apply and look
        forward to speaking with you.
      </Text>

      <Flex
        as="form"
        variant={`${variantName}.formContainer`}
        name={formName ? formName : 'contactform'} //  this can not be dyanmic form names and details must be static      method='POST'
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <Input type="hidden" name="form-name" value={formName ? formName : 'contactform'} />
        <Input
          variant={`${variantName}.name`}
          name="name"
          type="text"
          id="name"
          mb={3}
          placeholder="Name"
          className="inputField name"
          required
        />

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
          }}
        >
          <Input
            variant={`${variantName}.email`}
            sx={{ width: '48%' }}
            type="email"
            name="email"
            id="email"
            mb={3}
            placeholder="Email"
            className="inputField email"
            required
          />
          <Input
            sx={{ width: '48%' }}
            variant={`${variantName}.name`}
            type="tel"
            name="phone"
            id="phone"
            mb={3}
            placeholder="Phone"
            className="inputField name"
            required
          />
        </Box>

        <Box sx={{ width: '100%' }}>
          <Select
            // defaultValue='Position You Are Applying For?'
            variant={`${variantName}.name`}
            name="locationapp"
            id="locationapp"
            mb={3}
            // placeholder='Position'
            className="inputField position"
          >
            <option value>The Location Your Are Applying For</option>
            <option value="Coastal Gourmet Catering, Various Locations">
              Coastal Gourmet Catering, Various Locations
            </option>
            <option value="Aspen Restaurant, Old Saybrook CT">Aspen Restaurant, Old Saybrook CT</option>
            {/* <option value="Latitude 41 Restaurant & Tavern, Mystic CT">
              Latitude 41 Restaurant & Tavern, Mystic CT
            </option> */}
            <option value="Mystic Market East, Mystic">Mystic Market East, Mystic</option>
            <option value="Mystic Market West, Mystic">Mystic Market West, Mystic</option>
            <option value="Mystic Market South, Old Saybrook">Mystic Market South, Old Saybrook</option>
            {/* <option value="Mystic Market Westport, Westport CT">Mystic Market Westport, Westport CT</option> */}
            <option value="Mystic Market Foxwoods, Ledyard CT"> Mystic Market Foxwoods, Ledyard CT</option>
          </Select>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Select
            // defaultValue='Position You Are Applying For?'
            variant={`${variantName}.name`}
            name="position"
            id="position"
            mb={3}
            // placeholder='Position'
            className="inputField position"
          >
            <option value>The Position Your Are Applying For</option>
            <option value="Kitchen (Dishwasher, Food Runner, Expeditor)">
              Kitchen (Dishwasher, Food Runner, Expeditor)
            </option>
            <option value="Culinary (Food Prep, Line Cook, Cooks & Chefs)">
              Culinary (Food Prep, Line Cook, Cooks & Chefs)
            </option>
            <option value="Server">Server</option>
            <option value="Bartender">Bartender</option>
            <option value="Host/Hostess">Host/Hostess</option>
            <option value="Crew Member/Cashier">Crew Member/Cashier</option>
            <option value="Utility Crew (back of house)">Utility Crew (back of house)</option>
            <option value="Manager">Manager</option>
          </Select>
        </Box>

        <Textarea
          name="message"
          id="message"
          variant={`${variantName}.message`}
          rows="6"
          mb={3}
          placeholder="Tell Us about your experience"
          className="textField message"
        />

        <Box sx={{ width: '100%' }}>
          <Input
            variant={`${variantName}.name`}
            type="file"
            name="file"
            id="file"
            mb={1}
            sx={{ height: '2.5rem!important' }}
            placeholder="Upload Resume"
            className="inputField file"
            accept=".pdf, .jpg, .doc, .docx, .txt"
          />
          <label
            style={{
              opacity: '0.7',
              padding: '0rem 0.25rem',
              fontSize: '0.9rem'
            }}
          >
            Upload Resume (Optional, max file size: 3mb, accepts: .pdf, .jpg, .doc, .docx, .txt)
          </label>
        </Box>

        {isMultiLocationSite ? (
          <Input sx={{ display: 'none !important' }} name="location" id="location" value={city} placeholder={city} />
        ) : (
          ''
        )}
        <Button className="button submitBtn" type="submit">
          Submit
        </Button>
      </Flex>

      <Text as="p" sx={customStyles.disclaimer}>
        The Coastal Gourmet Group is an Equal Employment Opportunity Employer. All qualified applicants will receive
        consideration for employment without regard to race, national origin, gender, age, religion, disability, sexual
        orientation, or marital status .Veterans of the US Armed Forces and Members of federally recognized tribes
        receive preference in hiring.
      </Text>
    </Flex>
  )
}

const customStyles = {
  title: {
    color: 'black',
    fontSize: ['2rem', '', '2.5rem'],
    marginBottom: '2rem'
  },
  disclaimer: {
    opacity: '0.7',
    marginTop: '2rem',
    display: 'flex',
    maxWidth: '767px',
    fontSize: '0.7rem'
  }
}
